<template>
  <div class="page-user-medicationview">
    <div class="my-page">
      <div class="my-card">
        <!-- 是否已确认 -->
        <div
          class="confirm-box"
          :class="{ active: viewData.ConfirmedStateName === '已确认' }"
        ></div>
        <van-form>
          <van-field
            readonly
            v-model="viewData.ChildrenName"
            name="ChildrenName"
            label="用药宝贝"
            placeholder="用药宝贝"
          />
          <van-field
            readonly
            v-model="viewData.ClassName"
            name="ClassName"
            label="所在班级"
            placeholder="所在班级"
          />
          <van-divider />
          <van-field
            readonly
            v-model="viewData.CreateUserName"
            name="CreateUserName"
            label="委托人"
            placeholder="委托人"
          />
          <van-field
            readonly
            center
            v-model="viewData.CellPhone"
            name="CellPhone"
            label="委托人电话"
            placeholder="委托人电话"
          >
            <template #button>
              <van-icon
                size="35px"
                color="#07c160"
                name="phone-circle"
                @click.stop="callPhone(viewData.CellPhone)"
              />
            </template>
          </van-field>
          <van-field
            readonly
            v-model="viewData.CreateDate"
            name="CreateDate"
            label="委托时间"
            placeholder="委托时间"
          />
          <!-- <van-divider /> -->
          <van-field
            readonly
            v-model="viewData.PathogenyName"
            name="PathogenyName"
            label="当前症状"
            placeholder="当前症状"
          />
          <div
            class="drug-div"
            v-for="(item, i) in viewData.entrustedMedicationList"
            :key="i"
          >
            <van-field
              readonly
              v-model="viewData.entrustedMedicationList[i].DrugName"
              name="DrugName"
              label="药物名称"
              placeholder="药物名称"
            />
            <van-field
              readonly
              v-model="viewData.entrustedMedicationList[i].Dosage"
              name="Dosage"
              label="用药量"
              placeholder="用药量"
            />
            <van-field
              readonly
              v-model="viewData.entrustedMedicationList[i].MedicationTimeStr"
              name="MedicationTimeStr"
              label="用药时间"
              placeholder="用药时间"
            />
            <van-field
              readonly
              v-model="viewData.entrustedMedicationList[i].AdverseReaction"
              name="AdverseReaction"
              label="药物不良反应"
              placeholder="药物不良反应"
            />
          </div>
          <van-field
            readonly
            v-model="viewData.ConfirmedUserName"
            name="ConfirmedUserName"
            label="委托老师"
            placeholder="委托老师"
          />
          <van-field
            readonly
            v-model="viewData.CopyUserName"
            name="CopyUserName"
            label="抄送老师"
            placeholder="抄送老师"
          />
          <van-field
            readonly
            v-model="viewData.Desc"
            rows="2"
            label="家长备注"
            type="textarea"
            placeholder="家长备注"
          />
          <van-field
            readonly
            v-model="viewData.ConfirmedTime"
            name="ConfirmedTime"
            label="确认时间"
            placeholder="确认时间"
          />
          <van-field
            readonly
            v-model="viewData.ConfirmedDesc"
            rows="2"
            label="老师备注"
            type="textarea"
            placeholder="老师备注"
          />
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import { Button, Form, Field, Uploader, Empty, Divider, Icon } from 'vant'
export default {
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Button.name]: Button,
    [Empty.name]: Empty,
    [Divider.name]: Divider,
    [Icon.name]: Icon
  },
  data () {
    return {
      id: null,
      viewData: {
        entrustedMedicationList: []
      }
    }
  },
  mounted () {
    this.id = this.$route.query.id
    this.getDetail()
  },
  methods: {
    getDetail () {
      this.$axios
        .get('/api/Pathogeny/Get', {
          ID: this.id
        })
        .then(res => {
          this.viewData = res.data
        })
    },
    review (file) {
      if (file.url) {
        window.open(file.url)
      }
    },
    edit () {
      this.$toast('功能正在开发中...')
    },
    callPhone (phoneNumber) {
      if (phoneNumber) {
        window.location.href = 'tel://' + Number(phoneNumber)
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import './view.less';
</style>
