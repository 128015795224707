<template>
  <!-- 宝贝详情 -->
  <div class="childenDetails">
    <div class="topCard">
      <div class="my-card" style="margin:0px">
        <!-- 图片 -->
        <div class="imgBox">
          <van-image
            width="50"
            height="50"
            radius="50%"
            :src="ProfilePicture"
            class="img"
          />
        </div>
        <!-- 信息 -->
        <div class="childenInfo">
          <p>{{ ChildrenName }}</p>
          <p>{{ SchoolName }} - {{ className }}</p>
          <div @click="enterBadgeRecord">
            <img
              src="../../../../../assets/home/20.png"
              style="width: 20px; height: 20px"
            />
            <img
              src="../../../../../assets/home/19.png"
              style="width: 20px; height: 20px; margin: 0 5px"
            />
            <img
              src="../../../../../assets/home/18.png"
              style="width: 20px; height: 20px"
            />
            <span style="margin-left: 10px">{{ BadgeRecordCount }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 学年数据渲染 -->
    <div class="classInfo">
      <div style="margin-top: 10px">
        <div class="text">
          <span
            ><img
              src="../../../../../assets/home/zongjie.png"
              style="width: 30%"
          /></span>
        </div>
        <!-- 寄语 -->
        <div class="tabList">
          <div
            v-for="(item, index) in ClassHistoryDtos"
            :key="item.ID"
            @click="tabClick(index)"
            :class="[currentIndex == index ? 'active' : 'disactive']"
            style="margin-right: 10px;position: relative;"
          >
            <img
              src="../../../../../assets/home/sun.png"
              style="width:80px;height:40px"
            />
            <span style="position: absolute;left: 25px;top:15px;">{{
              item.ClassName
            }}</span>
          </div>
          <!-- <van-tag
            round
            size="medium"
            
          >
           
          </van-tag> -->
        </div>
      </div>
      <div class="scollView" style="flex: 1; overflow: auto">
        <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
          success-text="刷新成功"
        >
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="_getMoreList"
          >
            <div
              class="classContent"
              v-for="(item, index) in classList"
              :key="item.ID"
            >
              <!-- 寄语内容卡片 -->
              <div
                class="my-card"
                v-show="item.ChildrenSummaryType == 1"
                :class="[item.isLast ? 'last' : index == 0 ? 'first' : '']"
              >
                <div class="">
                  <div class="imgTavs">
                    <img
                      src="../../../../../assets/home/teacherWord2.png"
                      style="width: 60px; height: 24px"
                      v-if="item.SummaryType == 2"
                    />
                    <img
                      src="../../../../../assets/home/parentWord2.png"
                      style="width: 60px; height: 24px"
                      v-else
                    />
                  </div>
                  <p style="margin-top: 10px">
                    {{ item.Content }}
                  </p>
                  <div class="topImgList">
                    <div style="display:flex;flex-wrap:wrap;width:100%">
                      <div
                        v-for="(itm, index) in item.AccessoryPostList"
                        :key="index"
                        class="img_item"
                      >
                        <div>
                          <div
                            style="width: 100%;height: 100px;margin-top: 10px;"
                          >
                            <van-image
                              radius="10"
                              style="width: 100%;height: 100%;"
                              :src="itm.url"
                              @click.stop="
                                () => {
                                  images = [];
                                  images = item.imgList;
                                  imgShow = true;
                                }
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <van-row gutter="20">
                      <van-col
                        span="8"
                        v-for="itm in item.AccessoryPostList"
                        :key="itm.url"
                      >
                        <div class="" style="width: 80px; height: 80px">
                          <van-image
                            radius="10"
                            :src="itm.url"
                            style="margin-top: 16px; width: 100%; height: 100%"
                            @click.stop="
                              () => {
                                images = [];
                                images.push(itm.url);
                                imgShow = true;
                              }
                            "
                          />
                        </div>
                      </van-col>
                    </van-row> -->
                  </div>
                  <div
                    v-show="item.isLast"
                    style="
                      text-align: right;
                      cursor: pointer;
                      margin-top: 20px;
                      display: flex;
                      align-items: center;
                      justify-content: flex-end;
                    "
                    @click="enterEvaluation"
                  >
                    <img
                      src="../../../../../assets/home/jiyu.png"
                      style="width: 16px; height: 16px"
                    />
                    <span style="margin-left: 5px; color: rgb(206 180 221)">{{
                      userType == 2 ? "教师寄语" : "家长寄语"
                    }}</span>
                  </div>
                </div>
              </div>
              <!-- 宝贝日常轨迹 -->
              <div
                class="dailyTime"
                v-show="item.ChildrenSummaryType == 2 && item.isChecked"
              >
                <div class="text" style="margin-top: 16px">
                  <span
                    ><img
                      src="../../../../../assets/home/guiji.png"
                      style="width: 30%"
                  /></span>
                </div>
              </div>
              <div
                class="babyTime"
                v-show="item.ChildrenSummaryType == 2"
                style="margin-top: 10px"
              >
                <div class="timeLine left">
                  <ul></ul>
                </div>
                <div class="right" style="flex: 1; margin-left: 20px">
                  <div class="" style="position: relative;">
                    <img
                      src="../../../../../assets/home/growthDate.png"
                      style="width:20%;height:25px"
                    />
                    <span
                      style="position: absolute;left: 20px;top:7px;color:#47afa7"
                      >{{ item.Date }}</span
                    >
                  </div>
                  <div
                    class="my-card"
                    style="border-radius: 10px; margin: 10px 0px"
                  >
                    <!-- 顶部用户信息 -->
                    <div class="topUseInfo">
                      <div class="left">
                        <van-image
                          width="20"
                          height="20"
                          radius="10"
                          :src="item.HeadUrl"
                        />
                        <span style="color: #a6a6a6">{{ item.RealName }}</span>
                      </div>
                      <div class="right">
                        {{ "#" + item.SummaryTypeName }}
                      </div>
                    </div>
                    <!-- 中间内容 -->
                    <div class="middleContent">
                      <p>{{ item.Content }}</p>
                      <div class="imgList">
                        <div class="img">
                          <div style="display:flex;flex-wrap:wrap;width:100%">
                            <div
                              v-for="(itm, index) in item.AccessoryPostList"
                              :key="index"
                              :class="
                                itm.url.substring(itm.url.lastIndexOf('.')) ==
                                  '.mp4' ||
                                itm.url.substring(itm.url.lastIndexOf('.')) ==
                                  '.mov'
                                  ? 'video_item'
                                  : 'img_item'
                              "
                            >
                              <div
                                v-if="
                                  itm.url.substring(itm.url.lastIndexOf('.')) ==
                                    '.mp4' ||
                                    itm.url.substring(
                                      itm.url.lastIndexOf('.')
                                    ) == '.mov'
                                "
                                style="width: 100%;height: 200px;margin-top:10px"
                              >
                                <video
                                  controls
                                  playsinline
                                  webkit-playsinline
                                  x-webkit-airplay="allow"
                                  preload="metadata"
                                  style="width: 100%;height: 200px;object-fit: cover;"
                                  loop
                                  class="video"
                                  :src="itm.url"
                                  :poster="
                                    itm.url +
                                      '?x-oss-process=video/snapshot,t_1,m_fast'
                                  "
                                ></video>
                              </div>
                              <div v-else>
                                <div
                                  style="width: 100%;height: 100px;margin-top: 10px;"
                                >
                                  <van-image
                                    radius="10"
                                    style="width: 100%;height: 100%;"
                                    :src="itm.url"
                                    @click.stop="
                                      () => {
                                        images = [];
                                        images = item.imgList;
                                        imgShow = true;
                                      }
                                    "
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- <van-row>
                            <van-col
                              class="img_item"
                              span="8"
                              v-for="itm in item.AccessoryPostList"
                              :key="itm.url"
                              style="margin-top: 6px"
                            >
                              <div class="" style="width: 100%; height: 80px">
                                <van-image
                                  radius="10"
                                  style="width: 100%; height: 100%"
                                  :src="itm.url"
                                  @click.stop="
                                    () => {
                                      images = [];
                                      images.push(itm.url);
                                      imgShow = true;
                                    }
                                  "
                                />
                              </div>
                            </van-col>
                          </van-row> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
      <!-- 底部按钮图片 -->
      <div class="bottomBtn" @click="enterDailyRecord">
        <img
          src="../../../../../assets/home/addBtn.png"
          style="width: 40px; height: 40px"
        />
      </div>
    </div>

    <bottomBar
      ref="bottomBar"
      :ChildrenName="ChildrenName"
      :ProfilePicture="ProfilePicture"
      :ChildrenID="ChildrenID"
    ></bottomBar>

    <!-- 图片预览 -->
    <van-image-preview v-model="imgShow" :images="images"> </van-image-preview>
  </div>
</template>

<script>
import {
  Tag,
  Col,
  Row,
  Image as VanImage,
  Icon,
  List,
  PullRefresh,
  ImagePreview
} from "vant";

import bottomBar from "@/components/common/bottomBar/index.vue";
import View from "../../../../Medication/MedicationListParent/view.vue";

export default {
  name: "ChildenDetails",
  components: {
    [Tag.name]: Tag,
    [VanImage.name]: VanImage,
    [Col.name]: Col,
    [Row.name]: Row,
    [Icon.name]: Icon,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    bottomBar,
    [ImagePreview.Component.name]: ImagePreview.Component
  },
  data() {
    return {
      userType: window.localStorage.getItem("UserType"),
      ChildrenID: "",
      ChildrenName: "",
      SchoolName: "",
      ProfilePicture: "",
      BadgeRecordCount: 0, // 徽标数
      ClassHistoryDtos: [], //历史数据
      currentIndex: 0,
      classList: [], //宝贝学年数据列表
      loading: false,
      finished: false, // false
      refreshing: false,
      params: {
        page: 0,
        limit: 10
      },
      imgShow: false,
      images: [],
      className: ""
    };
  },
  created() {
    this.ChildrenID = this.$route.query.id;
    this._getInfoMations(); //学生信息数据
  },
  methods: {
    // 学生信息
    async _getInfoMations() {
      const { data: res } = await this.$axios.get(
        "/api/ChildrenGrowthManual/main-childrenGrowthManual",
        {
          childrenID: this.ChildrenID
        }
      );
      this.ChildrenName = document.title = res.ChildrenName;
      this.SchoolName = res.SchoolName;
      this.ChildrenID = res.ChildrenID;
      this.ProfilePicture = res.ProfilePicture;
      this.BadgeRecordCount = res.BadgeRecordCount;
      this.ClassHistoryDtos = res.ClassHistoryDtos;
      this.className = res.ClassName;
    },
    // 根据学生班级获取下拉列表数据
    _getClassHistory() {
      let model = Object.assign(this.params, {
        childrenID: this.ChildrenID,
        classHistroyID: this.ClassHistoryDtos[this.currentIndex].ID
      });
      this.$axios
        .get("/api/ChildrenGrowthManual/ListByClassHistory", model)
        .then(res => {
          if (res.code == 200) {
            this.loading = false;
            if (this.refreshing) {
              this.refreshing = false;
            }
            //合并数组(深拷贝)
            this.classList = this.classList.concat(res.data);
            if (this.classList.length >= res.count) {
              this.finished = true;
            }
            // 判断是否存在儿童轨迹
            let isChildrenTrack = this.classList.some(item => {
              return item.ChildrenSummaryType == 2;
            });
            this.classList.forEach(item => {
              if (item.ChildrenSummaryType == 2) {
                if (
                  item.AccessoryPostList &&
                  item.AccessoryPostList.length > 0
                ) {
                  let videoList = [];
                  let imgList = [];
                  item.AccessoryPostList.forEach(accessItem => {
                    if (
                      accessItem.url.substring(
                        accessItem.url.lastIndexOf(".")
                      ) == ".mp4" ||
                      accessItem.url.substring(
                        accessItem.url.lastIndexOf(".")
                      ) == ".mov"
                    ) {
                      videoList.push(accessItem);
                    } else {
                      imgList.push(accessItem.url);
                    }
                  });
                  item.videoList = videoList;
                  item.imgList = imgList;
                }
              } else {
                if (
                  item.AccessoryPostList &&
                  item.AccessoryPostList.length > 0
                ) {
                  let imgList = [];
                  item.AccessoryPostList.forEach(accessItem => {
                    imgList.push(accessItem.url);
                  });
                  item.imgList = imgList;
                }
              }
            });
            if (isChildrenTrack) {
              // 返回第一个出现宝贝类型的数据索引
              let classIndex = this.classList.findIndex(item => {
                return item.ChildrenSummaryType == 2;
              });
              if (classIndex == 0) {
                //说明没有寄语
                this.classList[classIndex].isChecked = true;
              } else {
                // 返回最后一个是寄语的数据
                this.classList[classIndex - 1].isLast = true;
                // 第一个出现的宝贝轨迹数据
                this.classList[classIndex].isChecked = true;
              }
            } else {
              // 返回第一个出现宝贝类型的数据索引
              let classIndex = this.classList.findIndex(item => {
                return item.ChildrenSummaryType == 1;
              });
              // 返回最后一个是寄语的数据
              this.classList[classIndex - 1].isLast = true;
            }
          }
        });
    },
    // 获取更多下拉列表数据
    _getMoreList() {
      setTimeout(() => {
        this.params.page++;
        this._getClassHistory();
      }, 500);
    },
    // 进入寄语选择框
    enterEvaluation() {
      this.$refs.bottomBar.activeTab = 0;
      this.$refs.bottomBar.show = true;
    },
    // 进入点滴记录模块
    enterDailyRecord() {
      this.$refs.bottomBar.activeTab = 1;
      this.$refs.bottomBar.show = true;
    },
    // 进入徽章记录
    enterBadgeRecord() {
      this.$router.push({
        path: "/badgeRecord",
        query: {
          id: this.ChildrenID,
          isDetailsFlag: true
        }
      });
    },
    onRefresh() {
      this.params.page = 1;
      // 清空列表数据
      this.finished = false;
      this.loading = true;
      this.classList = [];
      this._getClassHistory();
    },
    // 班级切换
    tabClick(index) {
      this.currentIndex = index;
      this.finished = false;
      this.refreshing = false;
      this.classList = [];
      this.params.page = 1;
      this._getClassHistory();
    }
  }
};
</script>

<style lang="less" scoped>
.childenDetails {
  height: 100%;
  // background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  background: url("../../../../../assets/img/growthBg.png");
  background-size: cover;
  .bottomBtn {
    position: fixed;
    bottom: 5px;
    z-index: 100;
    text-align: center;
    width: 100%;
  }

  .topCard {
    // background-image: linear-gradient(#47AFA7, #ffffff);
    //height: 10%;
    padding: 40px 15px 0px;

    .my-card {
      height: 130px;
      padding: 0px;
      // margin-top: 20px;
      background: url("../../../../../assets/img/growthBanner.png") center
        center no-repeat;
      background-size: cover;
      .imgBox {
        text-align: center;
        height: 30px;

        .img {
          position: relative;
          bottom: 25px;
        }
      }

      .childenInfo {
        p {
          text-align: center;
        }

        p:first-child {
          font-weight: bold;
          font-size: 15px;
        }

        p:nth-child(2) {
          margin-top: 6px;
        }

        div {
          display: flex;
          margin-top: 12px;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .classContent {
    .topImgList {
      .img_item {
        width: 32%;
      }
      .img_item:nth-child(3n + 2) {
        margin: 0 2%;
      }
    }
    .last {
      border-bottom-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
    }

    .first {
      border-top-left-radius: 10px !important;
      border-top-right-radius: 10px !important;
    }
  }

  .classInfo {
    flex: 1;
    overflow: auto;
    //margin-top: 80px;
    padding: 0 15px;
    display: flex;
    // height: 100%;
    flex-direction: column;
    .topUseInfo {
      display: flex;
      justify-content: space-between;
      align-self: center;

      .left {
        display: flex;
        justify-content: flex-start;

        span {
          display: flex;
          align-items: center;
          margin-left: 5px;
        }
      }

      .right {
        color: #47afa7;
      }
    }

    .middleContent {
      margin: 20px 0;

      .imgList {
        margin-top: 10px;
        .img_item {
          width: 32%;
        }
        .img_item:nth-child(3n + 2) {
          margin: 0 2%;
        }
        .video_item {
          width: 100%;
        }
      }
    }

    .tabList {
      display: flex;
      justify-content: space-between;
      margin: 14px 0 10px;

      //height: 30px;
      .active {
        //background-color: #eeee00;
        color: #47afa7;
        font-weight: bold;
      }

      .disactive {
        //background-color: white;
        color: #a6a6a6;
      }
    }

    .my-card {
      margin: 0px;
      border-radius: 0px;
    }
  }

  .babyTime {
    height: 100%;
    position: relative;
    display: flex;

    .timeLine {
      height: 106%;
      width: 2px;
      position: absolute;
      background-color: #47afa7;
      margin-left: 5px;
      margin-top: 5px;

      ul {
        padding: 5px;
        list-style: none;
        background-color: #47afa7;
        border-radius: 50%;
        position: relative;
        right: 4px;
        z-index: 100;
      }
    }
  }

  .text {
    font-size: 16px;
    color: #000000;
    font-weight: bold;
    text-align: center;

    span {
      width: 100%;
      position: relative;

      &::after {
        display: inline-block;
        content: "";
        height: 5px;
        border-radius: 10px;
        width: 100%;
        // background-color: #ffe500;
        position: absolute;
        bottom: -3px;
        left: 50%;
        transform: translate(-50%, 0);
        opacity: 0.5;
      }
    }
  }
}
</style>
